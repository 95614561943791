<template>
  <hk-card header="Character builder info">
    <div class="card-body">
      <h2>Shieldmaiden character builder</h2>
      <p>
        When creating a new tool you need a good reason to do so. 
        Character builders already exist, but we feel an advanced one with a lot of freedom is still missing.
        So before we started building, we came up with one clear goal.
      </p>
      <blockquote>
        Create a character builder that allows you to make any class you want, even homebrew.
      </blockquote>
    </div>
  </hk-card>
</template>

<script>
export default {
  name: "CharacterBuilderInfo"
}
</script>